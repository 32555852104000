import styles from './style.module.css'
import {Button, Card, Divider, Input, message, Pagination, Spin, Tooltip} from "antd";
import Meta from "antd/es/card/Meta";
import React, {ChangeEvent, useEffect, useState} from "react";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { Typography } from 'antd';
import {useAppDispatch, useAppSelector} from "../../store/store";
import {addToBestsellers, deleteCar, getCarDetails, getCars, getOptions} from "../../store/main/mainAction";
import Compact from "antd/es/space/Compact";
import {AddCarModal} from "../addCarModal";
import {EditCarModal} from "../editCarModal";
const { Paragraph } = Typography;

export const Catalog = () => {
  const {cars, isLoading, count} = useAppSelector(state => state.mainSlice.cars);
  const [id, setId] = useState('')
  const [carId, setCarId] = useState('')
  const [addCarModal, setAddCarModal] = useState(false)
  const [editCarModal, setEditCarModal] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState<{skip: number, limit: number}>({
    skip: 0,
    limit: 10
  })

  const handleId = (e: ChangeEvent<HTMLInputElement>) => setId(e.target.value)

  const handleSubmit = () => {
    const regex = /(?:\/car\/|car\/)?([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/;
    const match = id.trim().match(regex);
    if (match) {
      setId('')
      return dispatch(deleteCar(match[1]));
    }
    message.error('Указан неверный формат ID')
    setId('')
  }
  const dispatch = useAppDispatch()

  const handlePagination = (skip: number, limit: number) => {
    setPaginationInfo({skip: skip - 1, limit})
    dispatch(getCars({skip: skip - 1, limit}))
  }

  const handleAddToBestsellers = async (id: string) => {
    dispatch(addToBestsellers(id));
  };

  const handleDelete = async (id: string) => {
    await dispatch(deleteCar(id))
    dispatch(getCars({...paginationInfo}))
  }

  const editCar = async (id: string) => {
    await dispatch(getCarDetails(id))
    setCarId(id)
    setEditCarModal(true)
  }

  useEffect(() => {
    dispatch(getCars({}))
    dispatch(getOptions({}))
  }, [])

  return (
    <>
      <div className={styles.actions}>
        <Button type="dashed" block onClick={() => setAddCarModal(true)}>
          <PlusCircleOutlined />
        </Button>
        <Search placeholder="input search text" enterButton className={styles.search}/>
      </div>
      <Divider />
      <Compact>
        <Input placeholder='ID машины' value={id} onChange={handleId}/>
        <Button disabled={!id} onClick={handleSubmit} type="primary">Удалить</Button>
      </Compact>
      <Divider />
      <div className={styles.catalog}>
        {
          isLoading ? <Spin size="large" className={styles.spin}/> : (
            cars.map((car, index) => (
              <Card
                key={index}
                className={styles.card}
                cover={
                  <img
                    alt="example"
                    className={styles.carImg}
                    src={car.views[0]}
                  />
                }
                actions={[
                  <Tooltip title="Редактировать машину">
                    <EditOutlined key="edit" onClick={() => editCar(car.id)}/>
                  </Tooltip>,
                  <Tooltip title="Добавить в бестселлеры">
                    <PlusOutlined key="addToBestseller" onClick={() => handleAddToBestsellers(car.id)}/>
                  </Tooltip>,
                  <Tooltip title="Удалить машину">
                    <DeleteOutlined key='delete' onClick={() => handleDelete(car.id)}/>
                  </Tooltip>,
                ]}
              >
                <Meta
                  title={car.model_brand}
                  description={car.year}
                />
                <Paragraph copyable={{text: car.id}}>Cкопировать ID машины</Paragraph>
              </Card>
            ))
          )
        }
      </div>
      <Pagination total={count} className={styles.pagination} onChange={handlePagination}/>
      <AddCarModal active={addCarModal} setActive={setAddCarModal}/>
      <EditCarModal active={editCarModal} setActive={setEditCarModal} id={carId}/>
    </>
  );
};

