import {Form, Input, Button, Col, Row} from 'antd';
import {FC} from "react";
import {LoginFormValues} from "../types/interfaces";
import {useAppDispatch} from "../store/store";
import {signIn} from "../store/main/mainAction";

export const LoginForm: FC = () => {
  const dispatch = useAppDispatch()
  const onFinish = (values: LoginFormValues) => {
    dispatch(signIn({login: values.email, password: values.password}));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={24} sm={24} md={20} lg={12} xl={11} xxl={9} style={{ maxWidth: '600px' }} className='formContainer'>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

