import {Button, Card, Divider, Flex, Input, message, Spin, Tooltip} from "antd";
import Compact from "antd/es/space/Compact";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {addToBestsellers, deleteFromBestsellers, getAllBestsellers} from "../../store/main/mainAction";
import styles from "./style.module.css";
import {DeleteOutlined} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";

export const Bestseller = () => {
  const dispatch = useAppDispatch()
  const {isLoading, cars} = useAppSelector(state => state.mainSlice.bestsellers)
  const [id, setId] = useState('')

  const handleId = (e: ChangeEvent<HTMLInputElement>) => setId(e.target.value)

  const handleSubmit = () => {
    const regex = /(?:\/car\/|car\/)?([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/;
    const match = id.trim().match(regex);
    if (match) {
      setId('')
      return dispatch(addToBestsellers(match[1]));
    }
    message.error('Указан неверный формат ID')
    setId('')
  }

  const deleteFromBs = (id: string) => {
    dispatch(deleteFromBestsellers(id))
  }

  useEffect(() => {
    dispatch(getAllBestsellers())
  }, [])
  return (
    <div>
      <Compact>
        <Input placeholder='ID машины' value={id} onChange={handleId}/>
        <Button disabled={!id} onClick={handleSubmit} type="primary">Добавить</Button>
      </Compact>
      <Divider />
      <Flex gap="large" wrap='wrap' className={styles.bestsellers}>
        {
          isLoading ? <Spin size='large' className={styles.spin}/> : (
            cars.map((car, index) => (
              <Card
                key={index}
                className={styles.card}
                cover={
                  <img
                    alt="example"
                    className={styles.carImg}
                    src={car.views[0]}
                  />
                }
                actions={[
                  <Tooltip title="Удалить из бестселлеров">
                    <DeleteOutlined key='delete' onClick={() => deleteFromBs(car.id)}/>
                  </Tooltip>,
                ]}
              >
                <Meta
                  title={car.model_brand}
                  description={car.year}
                />
              </Card>
            ))
            )

        }
      </Flex>
    </div>
  );
};

