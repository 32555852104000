import {Tabs, TabsProps} from "antd";
import {Catalog} from "./components/catalog";
import {Parser} from "./components/parser";
import {Bestseller} from "./components/bestsellers";
import {LoginForm} from "./loginForm";
import {useAppSelector} from "./store/store";

const tabs: TabsProps['items'] = [
  {
    key: '1',
    label: 'Каталог',
    children: <Catalog/>,
  },
  {
    key: '5',
    label: 'Парсер',
    children: <Parser/>,
  },
  {
    key: '6',
    label: 'Авто в наличии',
    children: <Bestseller/>
  }
];

const App = () => {
  const auth = useAppSelector(state => state.mainSlice.auth)
  return (
    <div style={{padding: '0 20px'}}>
      {
        auth ? <Tabs defaultActiveKey="1" items={tabs} size='large'/> : <LoginForm/>
      }
    </div>
  );
};

export default App;
