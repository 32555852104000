import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  addToBestsellers, changeCar,
  changeParserStatus, createCar, deleteCar,
  deleteFromBestsellers,
  getAllBestsellers, getCarDetails,
  getCars, getOptions,
  getParserStatus, signIn
} from "./mainAction";
import {Car, Cars, InitialStateTypes} from "../../types/interfaces";
import {message} from "antd";

const initialState: InitialStateTypes  = {
  cars: {
    cars: [] ,
    count: 0,
    isLoading: false,
  },
  auth: localStorage.getItem('accessToken'),
  parserStatus: {
    isLoading: false,
    status: false,
  },
  bestsellers: {
    cars: [],
    isLoading: false,
  },
  options: null,
  carDetails: null
}

const mainSlice = createSlice({
  name: 'mainSlice',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getCars.pending, (state) => {
        state.cars.isLoading = true
      })
      .addCase(getCars.fulfilled, (state, action:PayloadAction<Car>) => {
        state.cars = {...action.payload, isLoading: false}
      })
      .addCase(changeParserStatus.pending, (state) => {
        state.parserStatus.isLoading = true
      })
      .addCase(changeParserStatus.fulfilled, (state) => {
        state.parserStatus.isLoading = false
      })
      .addCase(getParserStatus.pending, (state) => {
        state.parserStatus.isLoading = true
      })
      .addCase(getParserStatus.fulfilled, (state, action) => {
        state.parserStatus = {status: action.payload.status, isLoading: false}
      })
      .addCase(getAllBestsellers.pending, (state) => {
        state.bestsellers.isLoading = true
      })
      .addCase(getAllBestsellers.fulfilled, (state, action: PayloadAction<Cars[]>) => {
        state.bestsellers = {cars: action.payload, isLoading: false}
      })
      .addCase(addToBestsellers.pending, (state) => {
        state.bestsellers.isLoading = true
      })
      .addCase(addToBestsellers.fulfilled, (state, action: PayloadAction<Cars[]>) => {
        state.bestsellers = {cars: action.payload, isLoading: false}
        message.success('Успешно добавлено в бестселлеры!');
      })
      .addCase(addToBestsellers.rejected, (state, action) => {
        if (action.payload === 400) {
          message.error('Эта машина уже в бестселлерах');
          state.bestsellers.isLoading = false
        }
      })
      .addCase(deleteFromBestsellers.pending, (state) => {
        state.bestsellers.isLoading = true
      })
      .addCase(deleteFromBestsellers.fulfilled, (state, action: PayloadAction<Cars[]>) => {
        state.bestsellers = {cars: action.payload, isLoading: false}
      })
      .addCase(deleteFromBestsellers.rejected, ( ) => {
        message.error('Что-то пошло не так!');
      })
      .addCase(getOptions.fulfilled, (state, action) => {
        state.options = action.payload
      })
      .addCase(deleteCar.fulfilled, () => {
        message.success('Успешно удалено')
      })
      .addCase(signIn.fulfilled, (state, action) => {
        const { success, accessToken } = action.payload;

        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
          state.auth = accessToken;
          message.success('Вы успешно зашли в аккаунт');
        } else if (!success && !accessToken) {
          message.error('Неверный логин или пароль');
        }
      })
      .addCase(getCarDetails.fulfilled, (state, action) => {
        state.carDetails = action.payload
      })
      .addCase(changeCar.fulfilled, (state, action) => {
        console.log(action.payload)
        message.success(`Успешно изменен`);
      })
        .addCase(createCar.fulfilled, (state, action) => {
          console.log(action.payload)
          message.success(`Успешно добавлен`);
        })

  }

})


export const {

} = mainSlice.actions

export default mainSlice.reducer
