import {FC, useEffect, useState} from "react";
import Title from "antd/es/typography/Title";
import {Button, Flex, Spin} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {changeParserStatus, getParserStatus} from "../../store/main/mainAction";


export const Parser: FC = () => {
  const dispatch = useAppDispatch()
  const {isLoading, status} = useAppSelector(state => state.mainSlice.parserStatus)

  const buttonStyles = {
    background: status ? '#22ab2c' : '#ab222c',
    color: 'white',
    width: '120px',
    height: '50px',
    fontSize: '20px'
  };

  const buttonText = status ? 'Enabled' : 'Disabled'

  const changeStatus = async () => {
    await dispatch(changeParserStatus({status: !status}))
    dispatch(getParserStatus())
  }

  useEffect(() => {
    dispatch(getParserStatus())
  }, [])

  return (
    <Flex vertical align='center'>
      <Title>Состояние парсера</Title>
      {
        isLoading ? <Spin size='large'/> : (
          <Button onClick={changeStatus} style={buttonStyles} type="primary" size='large'>{buttonText}</Button>
        )
      }
    </Flex>
  );
};

