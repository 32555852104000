export enum translateLabelCar {
  city = "Город",
    manufacturer = "Марка",
    model_brand = "Модель",
    capacity = "Объем",
    fuel = "Топливо",
    transmission = "КПП",
    year = "Год",
    month = "Месяц"
}

export interface Car {
  cars: Cars[];
  count: number;
  isLoading: boolean;
}

export interface Cars {
  id: string;
  createDate: string;
  updateDate: string;
  source_id: string;
  sourceUrl: string;
  model_brand: string;
  modification: string;
  year: string;
  month: number;
  capacity: string;
  transmission: string;
  fuel: string;
  mileage: number;
  bodyType: string;
  color: string;
  city: string;
  price: number;
  preview: string;
  views: string[];
  origin: string;
}

interface ParserStatus {
  isLoading: boolean;
  status: boolean;
}

interface Bestsellers {
  isLoading: boolean;
  cars: Cars[]
}

export interface CarDetail {
  id: string;
  createDate: string;
  updateDate: string;
  source_id: string;
  sourceUrl: string;
  model_brand: string;
  modification: string;
  year: string;
  month: number;
  capacity: string;
  transmission: string;
  fuel: string;
  mileage: number;
  bodyType: string;
  color: string;
  city: string;
  price: number;
  preview: string;
  views: string[];
  origin: string;
  flooding: string | null;
}

export interface InitialStateTypes {
  cars: Car;
  parserStatus: ParserStatus;
  bestsellers: Bestsellers;
  auth: string | null;
  options: Options | null;
  carDetails: CarDetail | null;
}

export interface FetchCarsOptions {
  skip?: string | number;
  limit?: number;
}

export interface CarQueryParams {
  city?: string;
  model_brand?: string;
  capacity?: string;
  fuel?: string;
  transmission?: string;
  year?: string;
}

export interface Options {
  city: string[];
  model_brand: string[];
  capacity: string[];
  fuel: string[];
  transmission: string[];
  year: string[];
}


export interface LoginFormValues {
  email: string;
  password: string;
}

export interface CarModalProps {
  active: boolean;
  setActive: (value: boolean) => void;
}
