import {createAsyncThunk} from "@reduxjs/toolkit";
import api, {secondAPI} from "../../request";
import {CarDetail, CarQueryParams, FetchCarsOptions} from "../../types/interfaces";
import axios from "axios";
import React from "react";

export const getCars = createAsyncThunk(
  'mainSlice/getCars',
  async ({limit = 10, skip = 0}: FetchCarsOptions) => {
    try {
      return (await api.post('/car/filter', {limit, skip})).data
    } catch (error) {
      return error
    }
  }
)

export const getCarDetails = createAsyncThunk(
  'mainSlice/getCarDetails',
  async (id: string) => {
    try {
       return (await api.get(`/car/${id}`)).data
    } catch (error) {
      return error
    }
  }
)

export const changeParserStatus = createAsyncThunk(
  'mainSlice/changeParserStus',
  async ({status} : {status: boolean}) => {
    try {
      return (await axios.post(secondAPI + `/api/v1/set-parsers-status?setCron=${status}`)).data
    } catch (error) {
      return error
    }
  }
)

export const getParserStatus = createAsyncThunk(
  'mainSlice/getParserStatus',
  async () => {
    try {
      return (await axios.get(secondAPI + '/api/v1/get-parser-status')).data
    } catch (error) {
      return error
    }
  }
)

export const getAllBestsellers = createAsyncThunk(
  'mainSlice/getAllBestsellers',
  async () => {
    try {
      return (await api.get('/car/getAll')).data
    } catch (error) {
      return error
    }
  }
)

export const addToBestsellers = createAsyncThunk(
  'mainSlice/addToBestsellers',
  async (id: string, {rejectWithValue}) => {
    try {
      return (await api.get(`/car/bestseller/${id}`)).data
    } catch (error: any) {
      return rejectWithValue(error.response.data.statusCode)
    }
  }
)

export const deleteFromBestsellers = createAsyncThunk(
  'mainSlice/deleteFromBestsellers',
  async (id: string, { rejectWithValue }  ) => {
    try {
      return (await api.delete(`/car/bestsellerDel/${id}`)).data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getOptions = createAsyncThunk(
  'catalogSlice/fetchOptions',
  async (data : CarQueryParams) => {
    try {
      return (await api.post('/car/all-options', data)).data
    } catch (error) {
      return error
    }
  }
)

export const deleteCar = createAsyncThunk(
  'catalogSlice/deleteCar',
  async (id: string) => {
    try {
      return (await api.delete(`/car/delete/${id}`)).data
    } catch (error) {
      return error
    }
  }
)


export const signIn = createAsyncThunk(
  'catalogSlice/signIn',
    async (data: {login: string, password: string}) => {
      try {
        return (await api.post(`/auth/login`, data)).data
      } catch (error) {
        return error
      }
    }
)


export const changeCar = createAsyncThunk(
  'catalogSlice/changeCar',
  async ({data, id}: { data: FormData, id: string }) => {
    try {
      console.log(id)
      return (await api.patch(`/car/update/${id}`, data)).data
    } catch (error) {
      return error
    }
  }
)

export const createCar = createAsyncThunk(
    'catalogSlice/createCar',
    async (body: any) => {
        try {
            console.log(body)
            return (await api.post(`/car/create`, body)).data
        } catch (error) {
            return error
        }
    }
)