import React, { FC, useEffect, useState, useRef } from "react";
import {Form, Input, Modal, Select, Button, Space, Divider, message, Upload, InputNumber} from "antd";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {CarModalProps, CarDetail, Options, Car, translateLabelCar} from "../../types/interfaces";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {changeCar, getCars} from "../../store/main/mainAction";

export const EditCarModal: FC<CarModalProps  & {id: string}> = ({ active, setActive, id }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch()
  const carDetails: any = useAppSelector((state) => state.mainSlice.carDetails);
  const initialOptions = useAppSelector((state) => state.mainSlice.options);
  const [options, setOptions] = useState<Options | any>(initialOptions);
  const [newOptionName, setNewOptionName] = useState<{ [key: string]: string }>({});
  const inputRef = useRef<{ [key: string]: any }>({});


  useEffect(() => {
    if (initialOptions) {
      setOptions(initialOptions)
    }
  }, [initialOptions])

  useEffect(() => {
    if (active && carDetails) {
      form.setFieldsValue({
        ...carDetails,
        mileage: carDetails.mileage,
        bodyType: carDetails.bodyType,
        color: carDetails.color,
        price: carDetails.price,
      });
    }
  }, [form, carDetails, active]);

  const onNameChange = (key: string, value: string) => {
    setNewOptionName((prev) => ({ ...prev, [key]: value }));
  };

  const addItem = (key: string) => {
    if (options) {
      const newName = newOptionName[key] || `New item ${options[key].length}`;
      const updatedOptions = { ...options, [key]: [...options[key], newName] };
      setOptions(updatedOptions);
      setNewOptionName((prev) => ({ ...prev, [key]: "" }));
      inputRef.current[key]?.focus();
    }
  };

  const generateSelectOptions = (optionArray: string[], key: string) => (
    <Select
      defaultValue={carDetails?.[key] as string}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Please enter item"
              ref={(el) => (inputRef.current[key] = el)}
              value={newOptionName[key] || ""}
              onChange={(e) => onNameChange(key, e.target.value)}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={() => addItem(key)}>
              Add item
            </Button>
          </Space>
        </>
      )}
      options={optionArray.map((item) => ({ label: item, value: item }))}
    />
  );

  const handleSubmit = async (value:any) => {
    // const data = {...carDetails, ...value}
    const formDataPreview = new FormData();
    Object.keys(value).forEach((key) => {
      if (key === 'views' && value[key]?.fileList) {
        value[key]?.fileList.forEach((file: any) => {
          formDataPreview.append(key, file.originFileObj);
        });
      } else if (key === 'price') {
        formDataPreview.append(key, parseInt(value[key], 10).toString());
      } else {
        formDataPreview.append(key, value[key]);
      }
    });

    await dispatch(changeCar({data: formDataPreview, id})).then(()=>{
      setActive(false)
    })
    setActive(false)
    dispatch(getCars({limit: 10, skip: 0}))
  }

  const uploadProps = {
    beforeUpload: () => false,
    multiple: true,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  if (!options) {
    return <h1>Loading...</h1>;
  }
  return (
    <Modal title="Информация об авто" open={active} footer={false} onOk={() => setActive(false)} onCancel={() => setActive(false)} width="50%">
      <Form form={form} onFinish={handleSubmit}>
        {Object.keys(options).map((key) => (
          <Form.Item label={translateLabelCar[key as keyof typeof translateLabelCar]} name={key} key={key}>
            {generateSelectOptions(options[key], key)}
          </Form.Item>
        ))}
        <Form.Item label="Изображения" name="views">
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Загрузить изображения</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Кузов" name="bodyType">
          <Input />
        </Form.Item>
        <Form.Item label="Цвет" name="color">
          <Input />
        </Form.Item>
        <Form.Item label="Пробег (км)" name="mileage">
          <Input />
        </Form.Item>
        <Form.Item label="Цена (USD)" name="price" rules={[{ required: true, message: "Please enter the price" }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
            Изменить
        </Button>
      </Form>
    </Modal>
  );
};
