import axios from 'axios';

export const secondAPI = process.env.REACT_APP_API_SECOND_KEY
const api = axios.create({
  baseURL: process.env.REACT_APP_API_KEY
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('accessToken');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
);


export default api;