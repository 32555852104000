import React, {FC, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {Button, Divider, Form, Input, InputNumber, message, Modal, Select, Space, Upload} from "antd";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {CarModalProps, translateLabelCar} from "../../types/interfaces";
import {createCar} from "../../store/main/mainAction";

export const AddCarModal: FC<CarModalProps> = ({active, setActive}) => {
  const [form] = Form.useForm();
  const initialOptions = useAppSelector(state => state.mainSlice.options);
  const [options, setOptions] = useState<any>(initialOptions);
  const [newOptionName, setNewOptionName] = useState<{ [key: string]: string }>({});
  const inputRef = useRef<{ [key: string]: any }>({});
  // const options = useAppSelector(state => state.mainSlice.options)

  const dispatch=useAppDispatch();
  useEffect(() => {
    if (initialOptions) {
      setOptions(initialOptions);
    }
  }, [initialOptions]);

  const onNameChange = (key: string, value: string) => {
    setNewOptionName((prev) => ({ ...prev, [key]: value }));
  };

  const addItem = (key: string) => {
    if (options) {
      const newName = newOptionName[key] || `New item ${options[key].length}`;
      const updatedOptions = { ...options, [key]: [...options[key], newName] };
      setOptions(updatedOptions);
      setNewOptionName((prev) => ({ ...prev, [key]: "" }));
      inputRef.current[key]?.focus();
    }
  };

  const generateSelectOptions = (optionArray: string[], key: string) => (
      <Select
          dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Input
                      placeholder="Please enter item"
                      ref={(el) => (inputRef.current[key] = el)}
                      value={newOptionName[key] || ""}
                      onChange={(e) => onNameChange(key, e.target.value)}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={() => addItem(key)}>
                    Add item
                  </Button>
                </Space>
              </>
          )}
          options={optionArray.map((item) => ({ label: item, value: item }))}
      />
  )
  const onFinish = async (values: any) => {
    const formDataPreview= new FormData()
    // formDataPreview.append('file', values.preview.file)

    Object.keys(values).forEach((key) => {

      if(
          // key==='preview' ||
          key==='views'){
        // formDataPreview.append(key, values.preview.file);
        // formDataPreview.append(key, values[key]?.file);
        values[key]?.fileList.forEach((file: any) => {
          formDataPreview.append(key, file.originFileObj);
        });
      }else{
        if (key === "price") {
          // Convert price to integer before appending to formData
          formDataPreview.append(key, parseInt(values[key], 10).toString());
        } else if (key === "bodyType" || key === "color" || key === "mileage") {
        formDataPreview.append(key, values[key]);
      } else {
          formDataPreview.append(key, values[key]);
        }
        // formDataPreview.append(key, values[key]);
      }
      // if(key==='views'){
      //   formDataPreview.append(key, values.views.file);
      // }else{
      //   formDataPreview.append(key, values[key]);
      // }

    });

    dispatch(createCar(formDataPreview)).then(()=> {
      setActive(false);
    })


  };
  const uploadProps = {
    beforeUpload: () => false,
    multiple: true,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const { Option } = Select;
  if (!options) {
    return <h1>loading</h1>
  }

  return (
    <>
      <Modal  title="Информация об авто" open={active} footer={false} onCancel={() => setActive(false)} width='50%'>
        <Form form={form} onFinish={onFinish}>
          {Object.keys(options).map((key) => (
              <Form.Item label={translateLabelCar[key as keyof typeof translateLabelCar]} name={key} key={key}>
                {generateSelectOptions(options[key], key)}
              </Form.Item>
            // <Form.Item label={key} name={key} key={key}>
            //   <Select>
            //     {// @ts-ignore
            //     options[key].map((optionValue) => (
            //       <Option key={optionValue} value={optionValue}>
            //         {optionValue}
            //       </Option>
            //     ))}
            //   </Select>
            // </Form.Item>
          ))}
          {/*<Form.Item label="preview" name="preview">*/}
          {/*  <Upload {...uploadProps}>*/}
          {/*    <Button icon={<UploadOutlined />}>Upload</Button>*/}
          {/*  </Upload>*/}
          {/*</Form.Item>*/}
          <Form.Item label="Изображения" name="views">
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Загрузить изображения</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Кузов" name="bodyType">
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Цвет" name="color">
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Пробег (км)" name="mileage">
            <Input style={{ width: "100%" }} />
          </Form.Item>
          {/* Add Price Field */}
          <Form.Item label="Цена (USD)" name="price" rules={[{ required: true, message: "Please enter the price" }]}>
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Добавить
          </Button>
        </Form>
      </Modal>
    </>
  );
};

